import React from 'react';
import styled from 'styled-components';

export default function SuccessInfo({ 
    email
} : { 
     email: string
}) {
  return (
    <Container>
      <Success>
        <h1>Поздравляем с покупкой!</h1>
        <Text>
          На почту <strong>{email}</strong>, была отправлена ссылка на просмотр результата.<br/><br/>
        </Text>
        <Questions>
          <h1>А если у меня будут вопросы?</h1>
          <Text>В письме будет ссылка на форму записи к специалисту.</Text>
        </Questions>
      </Success>
    </Container>
  );
}

const Container = styled.div`
  color: var(--text-color);
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Success = styled.div`
  ul {
    margin-top: 0.125rem;
    padding: 0 0 0 1rem;
  }
`;

const Questions = styled.div`
  margin-top: 3.125rem;
  margin-bottom: 4.375rem;
`;

const Text = styled.p`
  margin-bottom: 0.625rem;
  line-height: 150%;

  @media (max-width: 768px){
    margin-left: 1.5rem;
    margin-right: 2rem;
  }
`;
