import React, { useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import { InfoIcon } from '../../assets/icons';
import { s3urls } from '../../helpers/api';
import { PaymentCardPicker, DateTimeInput, InputContainer, Input, Button, PlaceInput } from '../../libs'
import { IPlan } from '.././Plan';
import * as EmailValidator from 'email-validator';
import AutoPredictionBlock from '.././AutoPredictionBlock';
import Promo from '.././Promo';
import { WIDGET_TYPE } from '../../App';
import { Link } from '../Form';
import { PaymentMethod, paymentMethods } from '../../helpers/payments';
// import ReRecaptcha, { TReReCaptchaApi } from '../ReRecaptcha';

dayjs.extend(utcPlugin);

// FIXME: duplicate
export interface IData {
    place?: {
      name: string;
      lat: number;
      lon: number;
    } | null;
    name?: string;
    email: string;
    date?: string | null;
    time?: string | null;
    promo? : string;
    autoprediction: boolean;
    country: string;
    // captchaToken: string | null | undefined;
}

export default function FormPayment({
  onSubmit,
  selectedPlan,
  autoPredictionPrice,
  type,
  promoError,
	paymentMethod,
	setPaymentMethod,
}: {
  onSubmit(data: IData) : Promise<any>;
  selectedPlan?: IPlan;
  autoPredictionPrice: {oldPrice: number, newPrice: number};
  type: WIDGET_TYPE;
  promoError: string | null;
	paymentMethod: PaymentMethod;
	setPaymentMethod(value: PaymentMethod): void;
}) {
  const [email, setEmail] = useState('');
  const [promo, setPromo] = useState('');
  const [showPromoInput, setShowPromoInput] = useState(false);
  const [autoprediction, setAutoprediction] = useState<boolean>(false)
  const [isWait, setIsWait] = React.useState<boolean>(false);

  // const captchaRef = React.useRef<TReReCaptchaApi | null>(null);

  let isDisabled = true;
  const isValidEmail = EmailValidator.validate(email);

  if (
    (selectedPlan && type !== 'partial' || type === 'partial') &&
    isValidEmail
  ) {
    isDisabled = false;
  }

  const calcDiscaunt = () => {
    if (selectedPlan) {
      const tariffDisdaunt = selectedPlan.priceOld - selectedPlan.price;
      if (autoprediction) {
        return autoPredictionPrice.oldPrice - autoPredictionPrice.newPrice + tariffDisdaunt;
      }
      return tariffDisdaunt;
    };
  };

  const onChangeAP = () => {
    if (promo) return
    setAutoprediction(!autoprediction)
  }
  const changePromo = (promo: string) => {
    if (autoprediction) setAutoprediction(false)
    setPromo(promo)
  }

  const onLocalSubmit = async () => {
    setIsWait(true);

    // captchaRef.current?.reset();
    // const captchaToken = await captchaRef.current?.executeAsync();

    const result = await onSubmit({
      email,
      promo: showPromoInput ? promo.trim() : '',
      autoprediction,
      country: paymentMethod.country,
      // captchaToken
    })
    setIsWait(false);
    return result;
  }

  const getLinks = () => {
		switch(paymentMethod.country) {
			case 'kz':
			case 'us':
				return (
					<Link href={s3urls.termsOfUseKZ} target='_blank' rel='noreferrer'>
          Пользовательское соглашение
        </Link>
				)
			default:
				return (
					<Link href={s3urls.termsOfUseRU} target='_blank' rel='noreferrer'>
          Пользовательское соглашение
        </Link>
				)
		}
	}

  return (
    <Container>
      <InputContainer label='Почта'>
        <StyledInput
          size='small'
          value={email}
          onChange={setEmail}
          placeholder='Ваш e-mail'
        />
      </InputContainer>
      {type !== 'partial' && <><TimeInfo>
        <InfoIcon />
        После оплаты, на указанную почту, придет ссылка в личный кабинет и потребуется ввести дату, время и место вашего рождения
      </TimeInfo></>}
      {type !== 'partial' && <>
        <Promo
            promo={promo}
            showPromoInput={showPromoInput}
            setShowPromoInput={setShowPromoInput}
            setPromo={changePromo}
            promoError={promoError}
        />

        <PaymentCardPicker
					items={paymentMethods}
					value={paymentMethod}
					onChange={(value: PaymentMethod) => setPaymentMethod(value)}
          showInfo={false}
				/>

        {selectedPlan && calcDiscaunt() !== 0 && <Saving>
          Ваша экономия
        <Discount>
            {calcDiscaunt()} {paymentMethod.symbol}
        </Discount>
        </Saving>}

        {
					!promo && <AutoPredictionBlock
											checked={autoprediction}
											onChange={onChangeAP}
											autoPredictionPrice={autoPredictionPrice}
											symbol={paymentMethod.symbol}/>
				}
      </>}
      <Button
        color="accent"
        onClick={onLocalSubmit}
        disabled={isDisabled || isWait}
      >
        Построить<Price>{selectedPlan ? ` за ${selectedPlan.price + (autoprediction ? autoPredictionPrice.newPrice : 0)} ${paymentMethod.symbol}` : ''} </Price>
      </Button>
      <Footer>
        Нажимая «Построить», вы принимаете <br />
        {getLinks()}
      </Footer>

      {/* <ReRecaptcha
        size="invisible"
        badge="bottomright"
        ref={captchaRef}
      /> */}
    </Container>
  );
}

const Container = styled.div`
  width: 42.5rem;
  max-width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;
  font-size: 0.875rem;

  & > :nth-child(-n + 9) {
    grid-column: 1 / 3;
  }
  & > button {
    margin-top: 0.7rem;
    text-align: center;
    color: var(--white-color);
    font-size: 1rem;
    background: var(--main-color);

    &[disabled] {
      color: var(--main-color);
      background: var(--main-light-color);
    }
  }

  @media (max-width: 768px){
    & > :nth-child(1) {
    grid-column: 1 / 3;
  }
  & > :nth-child(2) {
    grid-column: 1 / 3;
  }
}
`;

const TimeInfo = styled.div`
  display: flex;
  align-items: flex-start;
  text-align: left;
  color: var(--main-color);

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.4rem;
    fill: var(--main-color);
  }
`;

const Price = styled.span`
  font-size: 1.1rem;
`;

const Saving = styled.div`
  display: flex;
  font-size: 1.25rem;
  color: var(--gray-color);
`;

const Discount = styled.div`
  color: var(--main-color);
  margin-left: 0.4rem;
`;

const Footer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--gray-color);
  font-size: 1rem;
  margin-bottom: 4rem;

  @media (max-width: 768px){
    font-size: 0.8rem;
  }
`;

const StyledInput = styled(Input)`
  @media (max-width: 768px){
    font-size: 1rem;
  }
`

const PaymentWarning = styled.div`
  text-align: left;
  color: var(--orange-color);
`

const StyledDateTimeInput = styled(DateTimeInput)`
  @media (max-width: 768px){
    font-size: 1rem;
  }
`

const StyledPlaceInput = styled(PlaceInput)`
    @media (max-width: 768px){
    font-size: 1rem;
  }
`
