import React from 'react';
import styled from 'styled-components';

export default function SuccessInfo({ email, devPlanHref, isAutoprediction, isStaged }: { email: string, devPlanHref: string, isAutoprediction: boolean, isStaged?: boolean }) {
 

  return (
    <Container>
      <Success>
        <h1>Поздравляем с покупкой!</h1>
        {isAutoprediction? <Text>
          Мы отправили вам ссылки на почту <strong>{email}</strong>. 
          Или вы можете открыть <br/><a href={`${process.env.REACT_APP_DAWN}/idp/${devPlanHref}`}> План Развития</a> и <a href={`${process.env.REACT_APP_DAWN}/ap/${devPlanHref}`}>Автопрогноз</a> сейчас.
        </Text> : 
          <Text>
          На почту <strong>{email}</strong>, была отправлена ссылка на просмотр плана развития.<br/><br/>
          <a href={`${process.env.REACT_APP_DAWN}/idp/${devPlanHref}`}>Открыть в браузере</a>
          </Text>
        }
        <Questions>
          <h1>А если у меня будут вопросы?</h1>
          <Text>В письме будет ссылка на форму записи к специалисту.</Text>
          <Text>
            Он вам поможет:
            <ul>
              <li>Истолковать ИПР с учетом вашей жизненной ситуации</li>
              <li>
                Найти эффективные компенсаторные меры для проработки “минус
                состояний”
              </li>
            </ul>
          </Text>
        </Questions>
      </Success>
    </Container>
  );
}

const Container = styled.div`
  color: var(--text-color);
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2.8125rem 0;
  `;

const Success = styled.div`
  ul {
    margin-top: 0.125rem;
    padding: 0 0 0 1rem;
  }
`;
const Questions = styled.div`
  margin-top: 3.125rem;
  margin-bottom: 4.375rem;
`;

const Text = styled.p`
  text-align: left;
  margin-bottom: 0.625rem;
  margin-left: 4.43rem;
  line-height: 150%;

  @media (max-width: 768px){
    margin-left: 1.5rem;
    margin-right: 2rem;
  }
`;
