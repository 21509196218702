import styled from "styled-components";

interface TitleProps {
  step: number
  text: string
}

export const Title = (props: TitleProps) => {
  return (
    <TitleWrapper>
      <span>{props.step}</span>
      <p>{props.text}</p>
    </TitleWrapper>
  )
}

const TitleWrapper = styled.div`
  align-self: center;
  margin-top: 1.75rem !important;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;

  & > p {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 1.25rem;
  }

  & > span {
    width: 1.85rem;
    height: 1.85rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-color);
    background: var(--light-gray-color);
    border-radius: 50%;
    margin-right: 1.0625rem;
  }
`;
