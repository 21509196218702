import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { devtools } from 'zustand/middleware'
import { persist } from 'zustand/middleware'
import { getConfigPrices } from '../helpers/api'


export interface Prices {
	default: number
  birth: number
	finance: number
  relations: number
  autoprediction: number
  finance_capacity: number
}

export interface PricesState {
	prices?: Prices
	setPrices(token: string, partner: string, country: string): void
}

export const usePricesStore = create<PricesState>()(persist(devtools(immer(((set) => ({
	setPrices: async (token: string, partner: string, country: string) => {
		const result = await getConfigPrices(token, partner, country)
		
		set({
			prices: {
				default: result.default,
			  birth: result.birth,
				finance: result.default + result.finance,
			  relations: result.default + result.finance + result.relations,
			  autoprediction: result.autoprediction || 0,
			  finance_capacity: result.finance_capacity,
			}
		})
	}
})))), {name: 'usePricesStore', version: 1}))
