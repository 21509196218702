import { Checkbox } from "../libs";
import styled, { css } from "styled-components";

export default function AutoPredictionBlock ({
  checked,
  autoPredictionPrice,
  onChange,
	symbol,
}: {
  checked: boolean;
  autoPredictionPrice: {oldPrice: number, newPrice: number};
  onChange(): void;
	symbol: string
}) {

  if(!autoPredictionPrice?.newPrice) return null;

  return (
    <Container>
    <BoxInfoWrapper>
      <CheckboxWrapper>
        <StyledCheckbox
          onChange={onChange}
          checked={checked}
          color={'rgb(0, 188, 154)'}
        />
      </CheckboxWrapper>
      <Info>
        <Title>Добавить Автопрогноз на ваш персональный год</Title>
        <Description>Показывать расстановку сил, подсвечивает возможности для достижения в <br/>
          разных сферах жизни и предупреждает о потенциальных рисках
        </Description>
      </Info>
    </BoxInfoWrapper>
    <Price>
    <NewPrice>{autoPredictionPrice.newPrice} {symbol}</NewPrice>
    <OldPrice>{autoPredictionPrice.oldPrice} {symbol}</OldPrice>
  </Price>
  </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

const BoxInfoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`
const StyledCheckbox = styled(Checkbox)<{checked: boolean}>`
    width: 1.25rem;
    height: 1.25rem;
    margin-bottom: 0.5rem;
    margin-right: 0;
    font-size: 14px;
    color: white;
    border-radius: 0.35rem;
    user-select: 'none';
    border: 1px solid var(--main-color);

    & .box {
      align-items: flex-start;
    }

    ${(props) =>
      props.checked &&
        css`
        border: none;
      `}
`
const CheckboxWrapper = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.7rem;
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

`

const Title = styled.div`
  color: var(--text-secondary);
  margin-bottom: 0.5rem;
  font-size: 1rem;
`

const Description = styled.div`
  font-size: 0.875rem;
  color: var(--text-secondary);
  text-align: start;
  line-height: 140%;
`

const Price = styled.div`

  /* width: 100%;
  height: 2rem; */
  /* margin-top: 0.65rem !important; */
  margin-bottom: 0;
`;
const NewPrice = styled.div`
  font-size: 1.5rem;
`;

const OldPrice = styled.div`
  /* margin-top: 1rem !important; */
  font-size: 1rem;
  color: var(--tariff-name-color);
  text-decoration: line-through;
`;
