import { Error, INVALID_EMAIL, errorDefault, errorInvalidEmail } from "./errors";
import { MapData } from './types';


const apiUrl = `https://api.chronos.mg/api`;

export async function getMap(data: MapData, setError: (value: Error) => void) {
  try {

    const params = Object.entries(data).reduce((acc, next) => `${acc}${next[0]}=${next[1]}&`, '')

    let url = `${apiUrl}/circle?${params}`;

    const result = await fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'image/png' },
      credentials: 'include'
    });

    const blob = await result.blob()
    return URL.createObjectURL(blob);

  } catch (err) {
    console.log(err);
    setError(errorDefault)
  }
}