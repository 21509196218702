import ym from 'react-yandex-metrika';

const gTagId = 'GTM-WV37V38';
const ymId = '49958389';

export async function yandexEvent(event: any, type = 'reachGoal', id = ymId) {
  try {
    ym(id, type, event);
  } catch (error) {
    console.log('ym error', event, error)
  }
}

export function googleEvent(event: string, id: string = gTagId) {
  try {
    if (window.dataLayer) {
      window.dataLayer.push({
        'event': event,
        'user_id': id
      });
    } else {
      throw (new Error('dataLayer is not defined!'));
    }
  } catch (error) {
    console.error('google event error', event, error)
  }
}