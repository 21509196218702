 import { NotificationPopup } from "../../libs"
 import Form from "./components/Form"
 import { useState } from "react"
 import { Error } from "./helpers/errors"
 import styled from "styled-components"

export default function NatalMapApp({
  token,
  partner
}: {
  token: string
  partner: string
}){
  const [error, setError] = useState<Error | null>(null)
  return (
    <Container>
      <Form token={token} partner={partner} setError={value => setError(value)}/>
        {/* @ts-ignore */}
      {error && <StyledNotificationPopup
        type={'warning'}
        size={'small'}
        title={error.title}
        // @ts-ignore
        content={error.content}
        onClose={() => setError(null)}
      />}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
`

const StyledNotificationPopup = styled(NotificationPopup)`
  &>div {
    border-radius: 0.4rem;
  }

  .title {
    text-align: left;
    margin-top: 2rem;
  }

  .content {
    text-align: left;
    margin-top: 1.5rem;
  }

  @media (max-width: 460px) {
    &>div{
      height: 40%;
    }
  }
`
