import React from 'react';
import styled, { css } from 'styled-components'

export function PlanDescription({ id }: { id: string }) {
  return (
    <Container plan={id}>
      <div>Четыре рождения,</div><div>Энергия, Здоровье,</div><div>Профориентация</div>
      <BottomWrapper plan={id}>
      <Finance plan={id}>
        {id === 'finance' || id === 'relations' ? '+Финансы,' : 'Финансы,'}
      </Finance>
      {id === 'relations' ? (
        <RelationsWrapper>
          <Relations plan={id}>+Отношения</Relations>
          {/* <ForWoman>(только для женщин)</ForWoman> */}
        </RelationsWrapper>
      ) : (
        <Relations plan={id}>Отношения</Relations>
      )}
      </BottomWrapper>
    </Container>
  );
}

export function PlanDescriptionLablife({ id }: { id: string }) {
  console.log(id);
  return (
    <Container plan={id}>
      {id === 'default' && <>
        <div>Четыре рождения, Энергия, Здоровье, Профориентация, Финансы, Отношения</div>     
      </>}
      {id === 'birth' && <>
        <div style={{display: 'block'}}>
          Четыре рождения, <br /><i>Энергия, Здоровье, Профориентация, Финансы, Отношения</i>
        </div>     
      </>}
    </Container>
  );
}

const Container = styled.div<{ plan: string }>`
  font-size: 1rem;
  display: flex;  
  flex-direction: column; 
  align-items: flex-start;  
  justify-content: flex-start;
  margin-top: 0.25rem !important;
  margin-bottom: -0.05rem;
  ${props => props.plan === 'default' && css` 
  margin-bottom: 1.4rem;
  `}

  & > div {
    display: flex;
    align-self: flex-start !important;
    margin-top: 0.15rem !important;
    margin-right: 0.5rem !important;

    i {
      font-style: normal;
      text-decoration: line-through;
      color: var(--tariff-name-color);
    }
  }
`;

const RelationsWrapper = styled.div`
  margin-top: 0 !important;
  display: flex;
  justify-content: center;
`;

const Finance = styled.div<{ plan: string }>`
align-self: baseline !important;
  margin: 0 !important;
  text-decoration: line-through;
  color: var(--tariff-name-color);

  ${(props) =>
    props.plan === 'default' &&
    css`
      text-decoration: line-through;
    `}

  ${(props) =>
    (props.plan === 'finance' || props.plan === 'relations') &&
    css`
      text-decoration: none;
      color: var(--main-color);
    `}
`;

const Relations = styled.div<{ plan: string }>`
  margin: 0 !important;
  text-decoration: line-through;
  color: var(--tariff-name-color);

  color: var(--tariff-name-color);
  ${(props) => props.plan === 'relations' && css``}

  ${(props) =>
    props.plan === 'relations' &&
    css`
      text-decoration: none;
      color: var(--main-color);
    `}

    ${(props) =>
    props.plan === 'default' &&
    css`
      margin-left: 0.3rem !important;
    `}

    ${(props) =>
    props.plan !== 'default' &&
    css`
      margin: 0.2rem 0 0 0 !important;
    `}
`;

const BottomWrapper = styled.div<{ plan: string }>`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.plan === 'default' &&
    css`
      flex-direction: row;
      align-items: center;
    `}
`

const ForWoman = styled.div`
  margin-bottom: 0.15rem !important;
  margin-left: 0.3rem;
  align-self: end !important;
  white-space: nowrap;
  font-size: 0.5rem;
  font-weight: 500;
  color: black;
`;
