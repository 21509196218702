import styled from "styled-components"
import { useNatalSunMapStore } from "../helpers/store";
import { FooterContainer, StyledButton } from './Form';

export default function NatalMapSunResult() {
    
  const {mapImage, interpretation, data, setMapImage, setInterpretation, setData} = useNatalSunMapStore();

  const onBack = () => {
    setMapImage(undefined);
    setInterpretation(undefined);
    setData(undefined);
  };

  const goToFull = () => {
    window.location.href = "https://p.chronos.ru/natal-chart-cons";
  }

  return <Container>
      <Header><BackLink onClick={onBack}>← Поменять данные</BackLink></Header>
      <img src={mapImage} />
      <Interpretation>
        <Title>Описание личности на основе натальной карты</Title>
        <UserInfo>
            {data?.name}&nbsp;&nbsp;&nbsp;
            {data?.date}&nbsp;&nbsp;&nbsp;
            {data?.time.slice(0, 5)}&nbsp;&nbsp;&nbsp;
            {data?.place}
        </UserInfo>
        <Info>
            <span>Доступно описание личности по Солнцу</span>
            В полной версии у вас будет описание по Луне, Венере, Меркурию и другим планетам. 
        </Info>
        
        <Block>
            <h2>{interpretation?.sign.title}</h2>
            <Text dangerouslySetInnerHTML={{__html: interpretation?.sign.text || ''}}></Text>
            <Facts>
                {interpretation?.sign.facts.map(item => <Fact>
                    <FactIcon>{item.icon}</FactIcon>
                    <FactText>{item.text}</FactText>
                </Fact>)}
            </Facts>
        </Block>

        <Block>
            <h2>{interpretation?.house.title}</h2>
            <Text dangerouslySetInnerHTML={{__html: interpretation?.house.text || ''}}></Text>
            <Facts>
                {interpretation?.house.facts.map(item => <Fact>
                    <FactIcon>{item.icon}</FactIcon>
                    <FactText>{item.text}</FactText>
                </Fact>)}
            </Facts>
        </Block>
      </Interpretation>
      <Footer>
        <FooterContainer>
          <StyledButton
            name="submit-button"
            color="blue"
            onClick={goToFull}
          >
            Купить разбор натальной карты со скидкой
          </StyledButton>
        </FooterContainer>
        
      </Footer>
  </Container>
}

const Container = styled.div`
    width: 42.5rem;
    max-width: 85%;
    padding: 1rem 0 6rem;
    margin: 0 auto;

    > img {
        max-width: 100%;
    }
`

const Header = styled.div`
    margin-bottom: 1rem;
    text-align: left;
`

const BackLink = styled.span`
    color: var(--text-secondary);
    cursor: pointer;
`

const Interpretation = styled.div`
    text-align: left;
    text-wrap: balance;
    margin-top: 1rem;
`

const Title = styled.div`
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.25rem;
    color: var(--text-secondary);
    margin-bottom: 0.25rem;
`

const UserInfo = styled.div`
    font-family: Inter;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: var(--text-third);
    margin-bottom: 0.75rem;
`

const Info = styled.div`
    margin-bottom: 2rem;
    background: rgba(64, 147, 244, 0.04);
    padding: 1rem;
    border-radius: 0.5rem;
    font-size: 0.75rem;
    line-height: 1rem;
    color: var(--text-secondary);

    > span {
        display: block;
        color: var(--text-primary);
        font-family: Inter;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
        text-align: left;
        margin-bottom: 0.125rem;
    }

`

const Block = styled.div`
    margin-bottom: 2rem;
    color: var(--text-secondary);
    line-height: 1.5rem;

    > h2 {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2rem;
        margin-bottom: 0.5rem;
    }
`

const Text = styled.div`
    font-size: 1rem;
    margin-bottom: 1.25rem;
`

const Facts = styled.div`
    display: grid;
    grid-gap: 0.75rem;
    grid-template-columns: 1fr 1fr;
    
    @media (max-width: 460px) {
        grid-template-columns: 1fr;
        grid-gap: 1rem;
    }
`

const Fact = styled.div`
    background: var(--color-white);
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.05);
    padding: 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.02);
    border-radius: 1rem;
`

const FactIcon = styled.div`

`

const FactText = styled.div`

`

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--gray-color);
  font-size: 1rem;

  background: var(--white-color);
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  border-top: 1px solid var(--black-light-color);

  @media (max-width: 768px){
    button {
        font-size: 0.75rem;
    }
  }
`;