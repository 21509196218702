import { Input } from '../libs';
import react, { useState } from 'react';
import styled, { css } from 'styled-components';
import { ChevronDownIcon } from '../assets/icons';

export default function Promo (
  { 
    promo, 
    showPromoInput,
    setShowPromoInput,
    setPromo,
    promoError
  } : {
    promo: string;
    showPromoInput: boolean;
    setShowPromoInput: (v: boolean) => void;
    setPromo: (p: string) => void;
    promoError: string | null;
  }) {

  return (
    <Container showInput={showPromoInput}>
      <Before onClick={() => setShowPromoInput(!showPromoInput)}><p>Есть промокод?</p> <ChevronDownIcon /></Before>
      <InputWrapper showInput={showPromoInput}>
        <StyledInput
          size='small'
          value={promo}
          onChange={setPromo}
          placeholder='Ваш промокод'
        />
        <span>{promoError}</span>
      </InputWrapper>
    </Container>
  )
};


const Container = styled.div<{showInput: boolean}>`
  display: flex;
  flex-direction: column;
  min-height: 3rem;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  border-radius: 0.5rem;
  background-color: var(--bg-promo);
    ${props => props.showInput && css`
      padding-bottom: 0.8rem;
    `}

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`

const Before = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
  user-select: none;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bg-promo);
`

const InputWrapper = styled.div<{showInput: boolean}>`
  text-align: left;
  width: 100%;
  ${props => !props.showInput && css`
    display: none;
  `}
  span {
    margin-right: 0.5rem;
      justify-self: center;
      text-align: left;
      color: var(--red-color);
    }
`

const StyledInput = styled(Input)`
  text-align: start;
  @media (max-width: 768px){
    font-size: 1rem;
  }
`

