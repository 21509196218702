import { WIDGET_TYPE } from '../../App';
import { IPlan } from '../../components/Plan';

export const convert = (amount: number, currency: string) => {
	switch (currency) {
		case 'kz':
			return amount * 6
		case 'us':
			return Math.round(amount * 0.01)
		default:
			return amount
	}
}

export const getPlans = (token: string, currency: string) => {
  
  let basePrice: number = convert(1658, currency)
  let financePrice: number = convert(2088, currency)

  const plans: {
    [key in WIDGET_TYPE]: IPlan[]
  } = {
    default: [
      {
        id: 'default',
        title: 'Базовый план',
        price: 0,
        priceOld: basePrice,
        popular: false,
      },
      {
        id: 'finance',
        title: 'Финансы',
        price: 0,
        priceOld: financePrice,
        popular: false,
      },
      {
        id: 'relations',
        title: 'Финансы и отношения',
        price: 0,
        priceOld: convert(3249, currency),
        popular: true,
      },
    ],

    lablife: [
      {
        id: 'birth',
        title: '4 рождения',
        price: 0,
        priceOld: convert(320, currency),
        popular: false,
        type: 'lablife'
      }, {
        id: 'default',
        title: 'Базовый план',
        price: 0,
        priceOld: convert(3249, currency),
        popular: false,
        type: 'lablife'
      }
    ],
    partial: []
  }

  return plans
}
