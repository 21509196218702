import { fromDateTime, IPlace, Button, DateTimeInput, Input, InputContainer, PaymentCardPicker, PlaceInput } from '../libs';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import * as EmailValidator from 'email-validator';
import { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { WIDGET_TYPE } from '../App';
import { InfoIcon } from '../assets/icons';
import { mainApi, s3urls } from '../helpers/api';
import { PaymentMethod, paymentMethods } from '../helpers/payments';
import AutoPredictionBlock from './AutoPredictionBlock';
import GenderSelector, { TGender } from './GenderSelector';
import { IPlan } from './Plan';
import Promo from './Promo';

// import ReRecaptcha, { TReReCaptchaApi } from './ReRecaptcha';

dayjs.extend(utcPlugin);

// FIXME: duplicate
export interface IData {
  place: {
    name: string;
    lat: number;
    lon: number;
  } | null;
  name: string;
  email: string;
  gender: TGender;
  date: string | null;
  time: string | null;
  promo?: string;
  autoprediction: boolean;
  country: string;
  // captchaToken: string | null | undefined;
}

export interface IDataForPayment {
  email: string;
  promo?: string;
  autoprediction: boolean;
  country: string;
}

export default function Form({
  onSubmit,
  selectedPlan,
  autoPredictionPrice,
  type,
  promoError,
	paymentMethod,
	setPaymentMethod,
}: {
  onSubmit(data: IData): void;
  selectedPlan?: IPlan;
  autoPredictionPrice: { oldPrice: number, newPrice: number };
  type: WIDGET_TYPE;
  promoError: string | null
	paymentMethod: PaymentMethod
	setPaymentMethod(value: PaymentMethod): void
}) {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState<TGender>('female');
  const [time, setTime] = useState<string>('12:00:00');
  const [date, setDate] = useState<string | null>(null);
  const [place, setPlace] = useState<IPlace | null>(null);

  const [nameMessage, setNameMessage] = useState<string | undefined>();
  const [emailMessage, setEmailMessage] = useState<string | undefined>();
  const [timeMessage, setTimeMessage] = useState<string | undefined>();
  const [dateMessage, setDateMessage] = useState<string | undefined>();
  const [placeMessage, setPlaceMessage] = useState<string | undefined>();

  const [promo, setPromo] = useState('');
  const [showPromoInput, setShowPromoInput] = useState(false);
  const [autoprediction, setAutoprediction] = useState<boolean>(false);

  // const captchaRef = React.useRef<TReReCaptchaApi | null>(null);

  const isDisabled = useMemo((): boolean => {
    return Boolean(
      nameMessage || !name ||
      emailMessage || !email ||
      timeMessage || !time ||
      dateMessage || !date ||
      placeMessage || !place ||
      !gender
    );
  }, [nameMessage, name, emailMessage, email, timeMessage, time, dateMessage, date, placeMessage, place, gender]);

  const onNameChange = (name: string) => {
    const resultName = name.trim();
    setName(name);
    if (/^[a-z,а-я,ё,\s]*$/i.test(resultName)) {
      setNameMessage(resultName.length === 0 ? 'Имя не может быть пустым' : undefined);
    } else {
      setNameMessage('В имени могут быть только буквы');
    }
  }

  const onEmailChange = (email: string) => {
    setEmail(email);
    setEmailMessage(EmailValidator.validate(email) || email === '' ? undefined : 'Введите корректный email');
  }

  const onDateChange = (isoDate?: string) => {
    const message = 'Нужно ввести дату'
    if (isoDate) {
      const [year, month, day] = isoDate.split('T')[0].split('-');
      const date = `${day}.${month}.${year}`;
      const yearNumber = Number(year);
      setDate(date);
      setDateMessage(yearNumber < 1900 ? 'Введите корректную дату' : yearNumber > dayjs().year() ? 'Введите дату в прошлом' : undefined);
    } else {
      setDateMessage(message);
    }
  }

  const onTimeChange = (isoTime?: string) => {
    const message = 'Нужно ввести время'
    if (isoTime) {
      const time = dayjs(isoTime).utc().format('HH:mm:ss');
      setTime(time);
      setTimeMessage(undefined);
    } else {
      setTimeMessage(message);
    }
  }

  const onPlaceChange = (place: IPlace | string | null) => {
    if (typeof place === 'object') {
      setPlace(place);
      setPlaceMessage(undefined);
    } else {
      setPlace(place => ({ ...place!, lat: 0, lon: 0 }) );
      setPlaceMessage('Выберите город из списка');
    }
  }

  const checkErrorMode = (val: any) => Boolean(val) ? 'error' : 'normal';

  const calcDiscaunt = () => {
    if (selectedPlan) {
      const tariffDisdaunt = selectedPlan.priceOld - selectedPlan.price;
      if (autoprediction) {
        return autoPredictionPrice.oldPrice - autoPredictionPrice.newPrice + tariffDisdaunt;
      }
      return tariffDisdaunt;
    };
  };

  const onChangeAP = () => {
    if (promo) return;
    setAutoprediction(!autoprediction);
  }
  const changePromo = (promo: string) => {
    if (autoprediction) setAutoprediction(false);
    setPromo(promo);
  }

  const localISOString = (dateStr: string | null | undefined) => {
    if (!dateStr) {
      return '';
    }
    const parts = dateStr.split('.');
    const nowDate = new Date(+parts[2], +parts[1] - 1, +parts[0]);

    const currentTimeZoneOffset = nowDate.getTimezoneOffset();

    nowDate.setMinutes(nowDate.getMinutes() - currentTimeZoneOffset);
    return nowDate.toISOString();
  }

  const handleSubmit = async () => {

    // captchaRef.current?.reset();
    // const captchaToken = await captchaRef.current?.executeAsync();

    onSubmit({
      place,
      name: name.trim(),
      email: email.trim(),
      gender,
      date,
      time,
      promo: showPromoInput ? promo.trim() : '',
      autoprediction,
      country: paymentMethod.country,
      // captchaToken
    });
  }

  const getLinks = () => {
		switch(paymentMethod.country) {
			case 'kz':
			case 'us':
				return (
					<Link href={s3urls.termsOfUseKZ} target='_blank' rel='noreferrer'>
          Пользовательское соглашение
        </Link>
				)
			default:
				return (
					<Link href={s3urls.termsOfUseRU} target='_blank' rel='noreferrer'>
          Пользовательское соглашение
        </Link>
				)
		}
	}

  return (
    <FormContainer>
      <InputContainer label='Имя' message={nameMessage}>
        <StyledInput
          name="username"
          size='small'
          value={name}
          onChange={onNameChange}
          placeholder='Как Вас зовут?'
          mode={checkErrorMode(nameMessage)}
          error={false}
        />
      </InputContainer>
      <InputContainer label='Почта' message={emailMessage}>
        <StyledInput
          name="email"
          size='small'
          value={email}
          onChange={onEmailChange}
          mode={checkErrorMode(emailMessage)}
          placeholder='Ваш e-mail'
          error={false}
        />
      </InputContainer>

      <InputContainer label='Пол' message={placeMessage}>
        <GenderSelector value={gender} setValue={setGender} />
      </InputContainer>

      <InputContainer label='Дата рождения' message={dateMessage}>
        <StyledDateTimeInput
          // name="asdfasdf"
          size='small'
          type='date'
          value={localISOString(date)}
          onChange={onDateChange}
          onInvalid={onDateChange}
          hideIcon={true}
          inputmode='numeric'
          mode={checkErrorMode(dateMessage)}
          error={false}
        />
      </InputContainer>

      <InputContainer label='Время рождения' message={timeMessage}>
        <StyledDateTimeInput
          size='small'
          type='time'
          value={fromDateTime('01.02.1989', time)}
          inputmode='numeric'
          hideIcon={true}
          onChange={onTimeChange}
          onInvalid={onTimeChange}
          mode={checkErrorMode(timeMessage)}
          utcMode
          error={false}
        />
      </InputContainer>

      {type !== 'partial' && <><TimeInfo>
        <InfoIcon />
        Вы можете изменить время рождения бесплатно написав в поддержку.
        <br />
        Подробнее в письме после оплаты.
      </TimeInfo></>}

      <InputContainer label='Место рождения' message={placeMessage}>
        <StyledPlaceInput
          size='small'
          value={place?.name || ''}
          onSelect={onPlaceChange}
          onChange={onPlaceChange}
          placeholder='Начните вводить место рождения'
          asyncDataFn={mainApi.places.bind(mainApi)}
          asyncDetailFn={mainApi.place.bind(mainApi)}
          mode={checkErrorMode(placeMessage)}
          lang="ru"
          error={false}
        />
      </InputContainer>

      {
				type !== 'partial' && <>
	        <Promo
	          promo={promo}
	          showPromoInput={showPromoInput}
	          setShowPromoInput={setShowPromoInput}
	          setPromo={changePromo}
	          promoError={promoError}
	        />

	        <PaymentCardPicker
						items={paymentMethods}
						value={paymentMethod}
						onChange={setPaymentMethod}
            showInfo={false}
          />

	        {selectedPlan && calcDiscaunt() !== 0 && <Saving>
	          Ваша экономия
	          <Discount>
	            {calcDiscaunt()} {paymentMethod.symbol}
	          </Discount>
	        </Saving>
	        }

	        {
						!promo && <AutoPredictionBlock
												checked={autoprediction}
												onChange={onChangeAP}
												autoPredictionPrice={autoPredictionPrice}
												symbol={paymentMethod.symbol}/>
					}
	      </>
			}

      <Button
        name="submit-button"
        color="accent"
        onClick={handleSubmit}
        disabled={isDisabled}
      >
        Построить<Price>{selectedPlan ? ` за ${selectedPlan.price + (autoprediction ? autoPredictionPrice.newPrice : 0)} ${paymentMethod.symbol}` : ''} </Price>
      </Button>
      <Footer>
        Нажимая «Построить», вы принимаете <br />
        {getLinks()}
      </Footer>

      {/* <ReRecaptcha
        size="invisible"
        badge="bottomright"
        ref={captchaRef}
      /> */}
    </FormContainer>
  );
}

export const Link = styled.a`
  color: var(--main-color);
`

const FormContainer = styled.div`
  width: 42.5rem;
  max-width: 85%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;
  font-size: 0.875rem;

  & > :nth-child(3), & > :nth-child(n + 6) {
    grid-column: 1 / 3;
  }
  & > button {
    margin-top: 0.7rem;
    text-align: center;
    color: var(--white-color);
    font-size: 1rem;
    background: var(--main-color);

    &[disabled] {
      color: var(--main-color);
      background: var(--main-light-color);
    }
  }

  @media (max-width: 768px){
    & > :nth-child(1) {
    grid-column: 1 / 3;
  }
  & > :nth-child(2) {
    grid-column: 1 / 3;
  }
}
`;

const TimeInfo = styled.div`
  display: flex;
  align-items: flex-start;
  text-align: left;
  color: var(--main-color);

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.4rem;
    fill: var(--main-color);
  }
`;

const Price = styled.span`
  font-size: 1.1rem;
`;

const Saving = styled.div`
  display: flex;
  font-size: 1.25rem;
  color: var(--gray-color);
`;

const Discount = styled.div`
  color: var(--main-color);
  margin-left: 0.4rem;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--gray-color);
  font-size: 1rem;
  margin-bottom: 4rem;

  @media (max-width: 768px){
    font-size: 0.8rem;
  }
`;

const StyledInput = styled(Input) <{ error: boolean }>`
  ${props => props.error && css`
    input {
      border-color: var(--red-color);
    }
  `};

  @media (max-width: 768px){
    font-size: 1rem;
  }
`

const StyledDateTimeInput = styled(DateTimeInput) <{ error: boolean }>`
  ${props => props.error && css`
    border-color: var(--red-color);
  `};

  @media (max-width: 768px){
    font-size: 1rem;
  }
`

const StyledPlaceInput = styled(PlaceInput)<{error: boolean}>`
  /* ${props => props.error && css`
    border: 1px solid var(--red-color);
  `};   */

  @media (max-width: 768px){
    font-size: 1rem;
  }
`
const PaymentWarning = styled.div`
  text-align: left;
  color: var(--orange-color);
`
