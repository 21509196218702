import React from 'react';
import styled, { css } from 'styled-components';
import { OkMainIcon } from '../assets/icons';

export type TGender = 'male' | 'female' | null;

const options: {
  title: string;
  value: TGender
}[] = [{
  title: 'Мужской',
  value: 'male'
}, {
  title: 'Женский',
  value: 'female'
}]

export default function GenderSelector({
  value,
  setValue
}: {
  value: TGender,
  setValue: (value: TGender) => void
}){
  return <Container>
    {options.map(item => 
      <Button 
        key={item.value}
        active={value === item.value}
        onClick={() => setValue(item.value)}
      >
        {value === item.value && <OkMainIcon />}
        {item.title}
      </Button>
    )}
  </Container>
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1px;
  width: 100%;
  box-sizing: border-box;
  background: var(--ui-input-border-color);
  border: var(--ui-input-border-size) solid var(--ui-input-border-color);
  border-radius: var(--ui-border-radius-medium);
  font-size: var(--ui-font-size-medium);
  line-height: var(--ui-line-height-medium);
  color: var(--text-secondary);
  text-align: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: var(--ui-border-radius-small);
  font-size: var(--ui-font-size-small);
  line-height: var(--ui-line-height-small);
`

const Button = styled.div<{active: boolean}>`
  background: var(--ui-input-background); 
  padding: var(--ui-input-padding-small);
  text-align: center;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  ${p => p.active && css`
    color: var(--text-primary);
    background-color: rgba(242, 242, 242, 1);
  `}

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
  }
`