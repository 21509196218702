import React from 'react';
import styled from 'styled-components';

export default function Sidebar({
	priceStart,
	symbol
}: {
	priceStart: number
	symbol: string
}) {
  return (

      <Container>
        <TitleAndSentenceW>
          <TitleGroup style={{marginRight: '2rem'}}>
          <Title>
            <span>Ваш индивидуальный план развития</span>
          </Title>
          <StartPrice>
            от <span>{priceStart} {symbol}</span>
          </StartPrice>
          <TitleOfSentence>
            Получите письменный разбор вашей натальной карты, который поможет:
          </TitleOfSentence>
          </TitleGroup>
          <SentenceWrapper>
          <GroupOfSentence>
            <div>
              <Emoji>📋</Emoji>
              <span>Определить своё предназначение и цели в жизни</span>
            </div>
            <div>
              <Emoji>👩‍🏫</Emoji>
              <span>Узнать свои таланты и найти любимое дело</span>
            </div>
            <div>
              <Emoji>📈</Emoji>
              <span>Научиться работать с энергетическими ресурсами</span>
            </div>
          </GroupOfSentence>
          </SentenceWrapper>
        </TitleAndSentenceW>
        <InfoCard>
          <InfoCardText>
            <p>
              После оплаты вы
              <br /> получите ссылку
              <br /> на документ
            </p>
          </InfoCardText>
          <ImgWrapper>
            <ImgFirst><img src={require('../assets/icons/info-card-img-1.png')} /></ImgFirst>
            <ImgSecond><img src={require('../assets/icons/info-card-img-2.png')} /></ImgSecond>
          </ImgWrapper>
        </InfoCard>
      </Container>
  );
}

const Container = styled.div`
position: relative;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1320px) {
    margin-top: 4rem;
    margin-bottom: 4rem;
    height: 50vh;
    width: 75%;
  }

  @media (max-width: 800px) {
    justify-content: space-around;
    height: 60vh;
    width: 75%;
  }

  @media (max-width: 375px) {
    margin-top: 2rem;
    width: 90%;
    height: 70%;
  }
`;

const TitleAndSentenceW = styled.div`
  @media (max-width: 1320px) {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      & > div {
        width: 50%;
      }
    }

    @media (max-width: 800px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > div {
        width: 90%;
      }
    }
`

const TitleGroup = styled.div`
  @media (max-width: 1320px) {
    margin-right: 2rem;
    width: 50%;
  }

  @media (max-width: 800px) {
    margin-right: auto;
    margin-left: auto;
    width: 90%;
  }

  @media (max-width: 375px) {
    width: 100% !important;
  }
`

const SentenceWrapper = styled.div`
  @media (max-width: 800px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90% !important;
    margin-left: 0 !important ;

}

  @media (max-width: 1320px) {
      margin-left: 2rem;
      width: 50%;
  }
`

const Title = styled.div`
  border: 1px solid var(--main-color);
  display: flex;
  justify-content: center;
  color: var(--main-color);
  border-radius: 3.75rem;

  & > span {
    display: flex;
    align-items: center;
    margin: 0.6rem 1.25rem;
    font-size: 1rem;
  }


`;

const StartPrice = styled.div`
  margin-top: 0.9rem;
  font-size: 4rem;
  font-weight: 500;
  color: var(--white-color);

  @media (max-width: 800px) {
    font-size: 3rem;
  }
`;

const GroupOfSentence = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  margin-top: 2.5rem;
  color: var(--white-color);

  & > div {
    display: flex;
    margin-bottom: 1.55rem;
    justify-content: center;

    @media (max-width: 768px) {
      margin-bottom: 0.8rem;
    }

    span {
      text-align: left;
      line-height: 1.1;
    }

  @media (max-width: 800px) {
    width: fit-content;
    font-size: 0.9rem;
  }

  @media (max-width: 1280px) {
    margin-left: -0.35rem;
  }

  @media (max-width: 1537px) {
    margin-left: -0.1rem;
  }
  }

  @media (max-width: 768px) {
    margin-top: 1.5rem;
  }
`;

const TitleOfSentence = styled.div`
  margin-top: 1.125rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center ;
  color: var(--gray-color);
  font-size: 1.125rem;
  line-height: 1.3;

  @media (max-width: 800px) {
    margin-top: 0.9rem;
    margin-bottom: 1rem;
  }


`;

const Emoji = styled.div`
  margin-top: -0.25rem;
  margin-right: 1.56rem;

  @media (max-width: 768px) {
    margin-right: 1.1rem;
    margin-top: 0rem;
    margin-left: 0.8rem;
  }
`;

const InfoCard = styled.div`
  margin-top: 2.4rem;
  background-color: var(--main-color);
  border-radius: 0.5rem;
  width: 95%;
  height: 7rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  overflow-y: hidden;

  @media (max-width: 1320px) {
    width: 50%;
    height: 7rem !important;
    margin-bottom: 1.5rem;
  }

  @media (max-width: 800px) {
    align-self: center;
    margin-top: 0rem;
    border-radius: 0.3rem;
    width: 94% !important;
    height: 7rem !important;
    margin-top: 0.5rem;
    margin-bottom: 0rem;
  }

  @media (max-width: 460px) {
    height: 5rem !important;
    }
`;

const ImgWrapper = styled.div`
  position: relative;
  height: 100%;
`

const ImgFirst = styled.div`
  height: 100%;
      & > img {
    margin-right: 1rem;
    width: 7.8rem;
    height: 100% !important;
  }
`

const ImgSecond = styled.div`
overflow: hidden;
  position: absolute;
  right: 0rem;
  top: 0.8rem;

  & > img {
    width: 7.8rem;
    right: 1rem;
    height: 100% !important;
  }
`

const InfoCardText = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.1rem;
  line-height: 1.2;
  color: var(--white-color);
  width: 80%;

  & > p {
    font-size: 1.125rem;
    text-align: left;

    @media (max-width: 1400px) {
      font-size: 0.9rem;
    }

    @media (max-width: 1320px) {
      font-size: 1.125rem;
    }

    @media (max-width: 840px) {
      font-size: 1rem;
    }

    @media (max-width: 460px) {
      font-size: 0.9rem;
    }

    @media (max-width: 380px) {
      font-size: 0.8rem;
    }
  }
`;
