import { Error, errorDefault } from "./errors";
import { MapData } from './types';


const apiUrl = (process.env.REACT_APP_BACKEND as string) + '/api';

export async function getMap(data: MapData, setError: (value: Error) => void) {
  try {

    const params = Object.entries(data).reduce((acc, next) => `${acc}${next[0]}=${next[1]}&`, '')

    let url = `${apiUrl}/circle?${params}`;

    const result = await fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'image/png' },
      credentials: 'include'
    });

    const blob = await result.blob()
    return URL.createObjectURL(blob);

  } catch (err) {
    console.log(err);
    setError(errorDefault)
  }
}

export async function getInterpretation(data: MapData, setError: (value: Error) => void) {
  try {

    const params = Object.entries(data).reduce((acc, next) => `${acc}${next[0]}=${next[1]}&`, '')

    let url = `${apiUrl}/get-time-date-info?${params}`;

    const result = await fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include'
    }).then((response) => response.json());;

    if (result.failed) {
      setError(errorDefault);
    } else {
      return result;
    }

  } catch (err) {
    console.log(err);
    setError(errorDefault);
  }
}

export async function sendUnisender(data: Pick<MapData, 'name' | 'email'>, setError: (value: Error) => void) {
  try {
    let url = `${apiUrl}/unisender/lablife/add-contact`;

    await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
      credentials: 'include'
    })

  } catch (err) {
    console.log(err);
    setError(errorDefault)
  }
}