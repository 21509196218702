export function searchParams() {
  return new URLSearchParams(window.location.search);
}

export const throttle = (func: Function, delay: number) => {
  let prev = 0;
  return (...args: any[]) => {
    let now = new Date().getTime();

    if (now - prev > delay) {
      prev = now;
      return func(...args);
    }
  }
}


export const getWidgetInstance = () => {
  console.log('check url', window.location)
  if (Boolean(~window.location.href.search('/ipr2023'))) return 'ipr2023';
  else return null;
}
